<template>
  <div class="container" style="margin: 0 10px">
    <Source :source="$t('supervise.indexSuperDetail.sourceTitle')" />

    <h3>{{ detail.name }}</h3>

    <br />

    <img class="img-item" alt="" :src="item"
      v-for="(item, index) in imgList" :key="index"
      @click="previewImg(index)"
    />

    <!-- 底部内容 -->
    <Footer />
  </div>
</template>

<script>
import BASE from "../../../utils/base";
import Source from "../components/Source";
import Footer from "../../../components/Footer";

import { ImagePreview } from "vant";
export default {
  name: "",
  components: { Source, Footer },
  activated() {
    this.id = this.$route.query.id;

    this.onLoad();
  },
  data() {
    return {
      id: "",
      detail: {
        img: "",
      },
    };
  },
  computed: {
    imgList() {
      return this.detail.img.split(",");
    },
  },
  watch: {},
  methods: {
    onLoad() {
      // 请求参数
      const params = {
        pageNo: 1,
        pageSize: 1,
        id: this.id,
      };

      this.axios
        .get(BASE.genUrl("zxSupervisePowerlistImg/list", params))
        .then((res) => {
          let data = res.data;

          if (data.result) {
            this.detail = data.result.records[0];
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },
    previewImg(startPosition) {
      const images = this.imgList;
      ImagePreview({ images, startPosition, closeable: true });
    },
  },
};
</script>

<style scoped>
.container {
}

h3 {
  color: #fff;
  font-size: 16px;
}
h4 {
  color: #fff;
  font-size: 14px;
}
.preview-img-box {
  width: 100%;
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
}
.img-item {
  width: 100%;
  margin: 0.3rem 0;
}
</style>
